var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start" },
        [
          _c("v-spacer"),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "auto" } },
            [
              _setup.props.addLink
                ? _c(
                    "v-btn",
                    {
                      staticClass: "primary mt-4",
                      attrs: { dark: "", to: _setup.props.addLink },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", center: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "text-right", attrs: { cols: "auto" } },
            [_vm._t("add-relation")],
            2
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
          "item-key": "index",
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: _setup.actionLink(item),
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.userValid`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(item.userValid === true ? "Ja" : "Nee") + " "
                  ),
                ]
              },
            },
            {
              key: `item.validFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.validFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.validTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.validTill)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.delete-link`,
              fn: function ({ item }) {
                return [
                  _setup.allowDeleteForDomain(item)
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            plain: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _setup.onRemoveLink(
                                                item.id
                                              )
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v(
                                            _vm._s(_setup.mdiTrashCanOutline)
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Verwijder koppeling")])]
                      )
                    : _vm._e(),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }